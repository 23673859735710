import Swal from 'sweetalert2';
import i18n from '@/i18n'

export default class AlertService {

    async confirmation(cb, title, description,cancel_btn,confirm_btn) {

        Swal.fire({
            title: title,
            text: description,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18n.t("facility.global.confirm"),
            cancelButtonText: i18n.t("facility.global.cancel"),
            allowOutsideClick: false,
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                cb();
            }
        })
    }

    async loading() {

        Swal.fire({
            imageUrl: "/images/loader.gif",
            showConfirmButton: false,
            allowOutsideClick: false,
            customClass: {
                container: "loader-alert",
            },
        });
    }
    async close() {

        Swal.close();
    }

    async success(title, description) {

        Swal.fire({
            icon: 'success',
            title: title,
            text: description,
            allowOutsideClick: false
        })
    }

    async error(title, description) {

        Swal.fire({
            icon: 'error',
            title: title,
            text: description,
            allowOutsideClick: false
        })
    }
}

export const alertService = new AlertService();
